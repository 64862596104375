import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_HOST = 'https://api.aok.network';

type Request = {
    method: string;
    value?: string;
    params?: Record<string, string>;
};

const useAPI = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState();

    const makeQuery = (request: Request) => {
        let query = `${API_HOST}/${request.method}`;

        if (request.value) {
            query += `/${request.value}`;
        }

        if (request.params) {
            query += Object.keys(request.params).reduce((stringParams, param, index) => {
                return `${stringParams}${index === 0 ? '?' : '&'}${param}=${request.params![param]}`;
            }, '');
        }

        return query;
    };

    const makeRequest = async (request: Request) => {
        setIsLoading(true);

        try {
            const response = await axios.get(makeQuery(request));
            setData(response.data.result);
            setIsLoading(false);

            return response.data.result;
        } catch (error) {
            console.error(error);

            throw error;
        }
    };

    return { isLoading, data, makeRequest, makeQuery };
};

export default useAPI;
