/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { Typography, Palette } from '../types';
import { GlobalStyle } from '../components';

interface ThemeProviderProps {
    children: any;
    mode?: 'light' | 'dark';
}

interface Theme {
    palette: Palette;
    typography: Typography;
}

declare module 'styled-components' {
    // eslint-disable-next-line
  export interface DefaultTheme extends Theme {}
}

type ThemeContextType = {
    switchMode?: (themeType: 'dark' | 'light') => void;
    theme: Theme;
};

const defaultLightPalette: Palette = {
    common: {
        darkblue: '#042444',
        lightblue: '#6b8ee2',
        blue: '#23578C',
        lightred: '#ff0000',
        red: '#8B112F',
        lightgray: '#f4f4f4',
        gray: '#999999',
        darkgray: '#666666',
        lightgreen: '#e5eaea',
    },
    initial: '#fff',
    primary: '#212121',
    secondary: '#003735',
    error: '#8B112F',
    warning: 'yellow',
    success: '#028561',
    text: '#031D38',
    background: 'white',
};

const defaultDarkPalette: Palette = {
    common: {
        darkblue: '#042444',
        lightblue: '#6b8ee2',
        blue: '#23578C',
        red: '#8B112F',
        gray: '#E9E9E9',
    },
    initial: '#fff',
    primary: '#fff',
    secondary: '#003735',
    error: '#8B112F',
    warning: 'yellow',
    success: '#028561',
    text: '#fff',
    background: '#031D38',
};

const defaultTypography: Typography = {
    fontFamily: 'Spoqa Han Sans Neo',
    fontSize: '12px',
    h3: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '1.45',
    },
    h4: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '1.5',
    },
    h2: {
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '41px',
    },
    h1: {
        fontFamily: 'Lora',
        fontSize: '32px',
        lineHeight: '1.28',
        fontWeight: 500,
    },
    tooltip: {
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 400,
    },
    body1: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 400,
    },
    body2: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
    },
    button1: {
        fontSize: '14px',
        lineHeight: '1.43',
        fontWeight: 400,
    },
    button2: {
        fontSize: '12px',
        lineHeight: '1.5',
        fontWeight: 400,
    },
};

const themeContextState: ThemeContextType = {
    theme: {
        palette: defaultLightPalette,
        typography: defaultTypography,
    },
};

export const ThemeContext = React.createContext<ThemeContextType>(themeContextState);

export const ThemeProvider = ({ children, mode }: ThemeProviderProps) => {
    const [themeMode, setThemeMode] = useState<'dark' | 'light'>(mode || 'light');
    const [lightPalette, setLightPalette] = useState<Palette>(defaultLightPalette);
    const [darkPalette, setDarkPalette] = useState<Palette>(defaultDarkPalette);

    const switchMode = (newMode: 'dark' | 'light') => setThemeMode(newMode);
    const theme = {
        palette: mode === 'light' ? lightPalette : darkPalette,
        typography: defaultTypography,
    };

    return (
        <ThemeContext.Provider
            value={{
                switchMode,
                theme,
            }}
        >
            <StyledProvider theme={theme}>
                <GlobalStyle />
                {children}
            </StyledProvider>
        </ThemeContext.Provider>
    );
};
