import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { AiFillSketchCircle, AiOutlineRight } from 'react-icons/ai';
import { format } from 'date-fns-tz';
import { useAPI } from '../hooks';
import { Container, Typography, InfoBlock, Button, Chart, NavButton } from '../components';
import { Table, Loading } from '../layout';
import { ReactComponent as CoinLogo } from '../img/icon-coin.svg';

interface TransactionsProps {
    className?: string;
}

type Info = {
    bestblockhash: string;
    blocks: number;
    chain: string;
    chainwork: string;
    difficulty: number;
    headers: number;
    mediantime: number;
    mempool: number;
    nethash: number;
    reward: number;
};

type Transaction = {
    amount: number;
    blockhash: string;
    txhash: string;
    timestamp: number;
    height: number;
};

const Transactions = ({ className }: TransactionsProps) => {
    const history = useHistory();
    const containerRef = useRef<HTMLDivElement>(null);
    const { isLoading, makeRequest } = useAPI();
    const [page, setPage] = useState<number>(1);
    const [transactions, setTransactions] = useState<Transaction[]>([]);

    const getNextPage = (refresh = false) => {
        makeRequest({
            method: 'v2/transactions',
            params: {
                page: refresh ? 1 : page,
            },
        }).then((res) => {
            if (refresh) {
                setTransactions((prev) =>
                    [
                        ...res.map((tx) => ({
                            ...tx,
                            timestamp: format(new Date(tx.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss'),
                        })),
                        ...prev,
                    ].filter((value, index, self) => {
                        return self.findIndex((v) => v.txhash === value.txhash) === index;
                    }),
                );
            } else {
                setPage((prev) => prev + 1);
                setTransactions((prev) =>
                    [
                        ...prev,
                        ...res.map((tx) => ({
                            ...tx,
                            timestamp: format(new Date(tx.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss'),
                        })),
                    ].filter((value, index, self) => {
                        return self.findIndex((v) => v.txhash === value.txhash) === index;
                    }),
                );
            }
        });
    };

    useEffect(() => {
        getNextPage();
        // setInterval(() => getNextPage(true), 20000);
    }, []);

    return (
        <>
            <Helmet>
                <title>Transactions</title>
            </Helmet>
            <Container ref={containerRef} className={className}>
                <div className="header">
                    <Typography variant="h1" component="h1">
                        AOK Transactions
                    </Typography>
                </div>
                <NavButton
                    rightIcon={<AiOutlineRight />}
                    onClick={() => history.push('/holders')}
                    className="nav-button"
                    title="AOK Holders"
                />
                {transactions.length > 0 && (
                    <div className="table-wrapper">
                        <Table
                            className="table"
                            data={transactions}
                            headers={[
                                {
                                    key: 'height',
                                    title: 'Height',
                                    sortable: false,
                                    isMobile: true,
                                },
                                {
                                    key: 'txhash',
                                    title: 'Transaction Hash',
                                    className: 'mono-font',
                                    sortable: false,
                                    isMobile: true,
                                    href: '/transaction/$text',
                                },
                                {
                                    key: 'amount',
                                    title: 'Amount',
                                    sortable: false,
                                },
                                {
                                    key: 'timestamp',
                                    title: 'Date & time',
                                    sortable: false,
                                    isMobile: true,
                                },
                            ]}
                        />
                    </div>
                )}

                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="button-load">
                        <Button backgroundColor="secondary" size="lg" title="Load more" onClick={() => getNextPage()} />
                    </div>
                )}
            </Container>
        </>
    );
};

export default styled(Transactions)<TransactionsProps>`
    flex-direction: column;
    display: flex;
    .nav-button {
        margin: 22px 0;
    }
    .icon {
        font-size: 16px;
        margin-left: 4px;
        line-heigth: 20px;
    }
    .description {
        display: flex;
        align-items: center;
    }
    #info-blocks {
        display: flex;
        flex-wrap: wrap;
        .active-block {
            flex: 2;
        }

        .common-block {
            flex: 1;
        }
    }
    .table-wrapper {
        margin-bottom: 20px;
    }
    .button-load {
        display: flex;
        justify-content: center;
    }
`;
