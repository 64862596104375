import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { InfoItemProps } from './components/InfoItem';
import { InfoItem } from './components';
import { Block } from '../types';
import { Button, Typography } from '../components';

interface InfoBlockProps extends InfoItemProps {
    data: InfoItemProps[];
    qrData?: string;
    loading?: boolean;
}

const ModalContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    .title {
        margin-top: 40px;
        opacity: 0.5;
    }
    .transaction-address {
        margin-top: 5px;
        max-width: 300px;
        word-wrap: break-word;
    }
    .btn {
        margin-top: 20px;
    }
`;

const InfoPanel = ({ title, description, qrData, className, data, loading }: InfoBlockProps) => {
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onCopy = () => {
        enqueueSnackbar('Successfully copied', { variant: 'success' });
    };

    return (
        <SkeletonTheme color="#325e5d" highlightColor="#194b49">
            <div className={className}>
                <Modal
                    open={open}
                    onClose={onCloseModal}
                    classNames={{
                        modal: 'modal',
                    }}
                >
                    <ModalContent>
                        <QRCode
                            className="qr-modal"
                            size={200}
                            value={qrData}
                            bgColor="transparent"
                            fgColor="#003735"
                        />
                        <Typography className="title" variant="button2" component="span" color="secondary">
                            {title}
                        </Typography>
                        <Typography className="transaction-address" variant="h4" component="span" color="secondary">
                            {description}
                        </Typography>
                        <CopyToClipboard text={description} onCopy={onCopy}>
                            <Button className="btn" backgroundColor="secondary" size="md" title="Copy hash" />
                        </CopyToClipboard>
                    </ModalContent>
                </Modal>
                <div className="blocks-wrapper">
                    <InfoItem className="infoitem-title" title={title} description={description} loading={loading} />
                    <div className="data-blocks">
                        {data.map((block) => (
                            <InfoItem
                                className="infoitem"
                                title={block.title}
                                description={block.description}
                                isCoin={block.isCoin}
                                loading={loading}
                                key={block.title}
                            />
                        ))}
                        {loading ? (
                            <Skeleton className="qr-sm" height={41} width={41} />
                        ) : (
                            <QRCode
                                onClick={onOpenModal}
                                className="qr-sm"
                                value={qrData}
                                bgColor="transparent"
                                fgColor="white"
                                size={41}
                            />
                        )}
                    </div>
                </div>
                {loading ? (
                    <Skeleton height={128} width={128} className="qr" />
                ) : (
                    <QRCode onClick={onOpenModal} className="qr" value={qrData} bgColor="transparent" fgColor="white" />
                )}
            </div>
        </SkeletonTheme>
    );
};

export default styled(InfoPanel)<InfoBlockProps>`
    background-color: ${({ theme }) => theme.palette.secondary};
    padding: 20px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    @media (max-width: 750px) {
        flex-direction: column;
        margin-left: -20px;
        margin-right: -20px;
    }
    .infoitem-title {
        word-wrap: break-word;
        max-width: 100%;
    }
    .blocks-wrapper {
        width: 80%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @media (max-width: 750px) {
            width: 100%;
        }
    }
    .data-blocks {
        margin-top: 17px;
        display: flex;
        flex-direction: row;
        width: 100%;
        @media (max-width: 750px) {
            flex-wrap: wrap;
        }
    }
    .infoitem {
        @media (max-width: 750px) {
            width: 33%;
            max-width: unset;
            margin-top: 20px;
        }
    }
    .qr {
        cursor: pointer;
        @media (max-width: 750px) {
            display: none;
        }
    }
    .qr-sm {
        cursor: pointer;
        margin-top: 20px;

        @media (min-width: 750px) {
            display: none;
        }
    }
`;
