import React from 'react';
import { useTransition, animated, config } from 'react-spring';

interface FullScreenMenuProps {
    children: JSX.Element | JSX.Element[];
    isOpen: boolean;
}

const FullScreenMenu = ({ children, isOpen }: FullScreenMenuProps) => {
    const fullscreenMenu = useTransition(isOpen, null, {
        from: {
            opacity: 0,
            transform: 'scale(0.80)',
            overflow: 'hidden',
        },
        enter: {
            width: '100%',
            opacity: 1,
            transform: 'scale(1)',
            backgroundColor: '#fff',
            position: 'absolute',
            height: '100vh',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            overflow: 'hidden',
            zIndex: 150,
        },
        leave: { opacity: 0, transform: 'scale(0.80)' },
        config: config.gentle,
    });
    return (
        <>
            {fullscreenMenu.map(
                ({ item, key, props }) =>
                    item && (
                        <animated.div key={key} style={props}>
                            {children}
                        </animated.div>
                    ),
            )}
        </>
    );
};

export default FullScreenMenu;
