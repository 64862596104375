import React from 'react';
import styled from 'styled-components';
import { Container, Typography } from '../components';

interface FooterProps {
    className?: string;
}

const Footer = ({ className }: FooterProps) => {
    return (
        <div className={className}>
            <Container>
                <div className="line" />
                <div className="copyright-wrapper">
                    <Typography
                        className="link"
                        color="success"
                        variant="button2"
                        component="a"
                        href="http://aok.network"
                    >
                        AOK.NETWORK
                    </Typography>
                    <Typography color="gray" variant="button2" component="span">
                        © {new Date().getFullYear()} AOK NETWORK
                    </Typography>
                </div>
            </Container>
        </div>
    );
};

export default styled(Footer)`
    margin-top: 60px;
    padding-bottom: 16px;
    .line {
        height: 1px;
        background-color: #dddddd;
    }
    .copyright-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 16px;
    }
    .link {
        text-decoration: none;
    }
`;
