import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { AmountInfo } from '../types';
import { Typography } from '../components';
import AmountInformation from './components/AmountInformation';
import { useUtils } from '../hooks';

type Input = {
    address: string;
    amount: number;
    category: string;
};

type Output = {
    address: string;
    amount: number;
    category: string;
    currency: string;
    timelock: number;
};

interface TransactionCardProps {
    type?: 'block' | 'transaction';
    coinstake: boolean;
    coinbase: boolean;
    date: string;
    hash: string;
    from: Input[];
    to: Output[];
    fee: number;
    className?: string;
    background?: boolean;
    color?: 'primary' | 'secondary' | string;
}

const TransactionCard = ({
    type,
    coinstake,
    coinbase,
    date,
    hash,
    from,
    to,
    fee,
    className,
    background,
    color,
}: TransactionCardProps) => {
    const history = useHistory();
    const { getStatusTitle } = useUtils();

    return (
        <div className={className}>
            {type === 'block' && (
                <div id="header">
                    <div>
                        <Typography component="h4">Transaction</Typography>
                        <Link to={`/transaction/${hash}`} className="hash-btn link">
                            <Typography
                                className="transaction-address"
                                variant="body1"
                                component="span"
                                color="primary"
                            >
                                {hash}
                            </Typography>
                        </Link>
                    </div>

                    <div className="coinstake-wrapper">
                        {(coinstake || coinbase) && (
                            <Typography className="coinstake" variant="button2" component="span" color="success">
                                {coinstake && 'Coinstake'}
                                {coinbase && 'Coinbase'}
                            </Typography>
                        )}
                        <Typography variant="body1" component="span" color="gray">
                            {date}
                        </Typography>
                    </div>
                </div>
            )}

            {type === 'transaction' && (
                <div id="header">
                    <Typography variant="body1" component="span" color="secondary">
                        {date}
                    </Typography>
                </div>
            )}

            <div id="body">
                <div className="from-block">
                    <Typography component="span" variant="h4" color="secondary">
                        Inputs
                    </Typography>
                    {from.map((input, index) => (
                        <AmountInformation
                            background={background}
                            color={(index + 1) % 2 === 0 ? 'lightgreen' : 'lightgray'}
                            amount={input.amount.toFixed(8)}
                            source={input.address}
                            coin={input.currency !== 'AOK' ? input.currency : undefined}
                            toClipboard
                            type="Address"
                            key={index}
                        />
                    ))}
                </div>
                <div className="to-block">
                    <Typography component="span" variant="h4" color="secondary">
                        Outputs
                    </Typography>
                    {to.map((output, index) => (
                        <AmountInformation
                            background={background}
                            color={(index + 1) % 2 === 0 ? 'lightgreen' : 'lightgray'}
                            amount={output.amount.toFixed(8)}
                            timelock={output.timelock}
                            source={output.address}
                            coin={output.currency !== 'AOK' ? output.currency : undefined}
                            toClipboard
                            type="Address"
                            key={index}
                        />
                    ))}
                    {!background && <div className="hr" />}
                    <AmountInformation
                        color={(to.length + 1) % 2 === 0 ? 'lightgreen' : 'lightgray'}
                        background={background}
                        amount={coinstake || coinbase ? -1 * fee : fee}
                        source={coinstake ? 'Staking reward' : 'Fee'}
                    />
                </div>
            </div>
        </div>
    );
};

TransactionCard.defaultProps = {
    type: 'block',
};

export default styled(TransactionCard)`
    margin-top: 22px;
    #header {
        padding: 20px;
        border: ${({ theme, type }) => type === 'transaction' && `1px solid #dddddd`};
        border-bottom: none;
        background-color: ${({ theme, type }) => type !== 'transaction' && theme.palette.common.lightgray};
        display: flex;
        justify-content: space-between;
        @media (max-width: 750px) {
            flex-direction: column;
        }
    }
    .transaction-address {
        margin-top: 8px;
        word-break: break-all;
    }
    .coinstake-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 750px) {
            margin-top: 20px;
        }
    }
    .coinstake {
        margin-right: 12px;
        display: block;
        border: ${({ theme }) => `1px solid ${theme.palette.success}`};
        border-radius: 4px;
        padding: 6px 10px;
        text-transform: capitalize;
    }
    #body {
        border: solid 1px #dddddd;
        border-top: none;
        padding: 32px 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 750px) {
            display: block;
        }
    }
    .from-block {
        flex: 1;
        margin-right: 42px;
        @media (max-width: 750px) {
            margin-right: 0;
        }
    }
    .to-block {
        flex: 1;
        @media (max-width: 750px) {
            margin-top: 20px;
        }
    }
    .hr {
        margin: 16px 0;
        background-color: ${({ theme }) => theme.palette.secondary};
        height: 1px;
        opacity: 0.1;
    }
    .hash-btn {
        color: ${({ theme }) => theme.palette.primary};
    }
`;
