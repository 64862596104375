import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '../../components';
import { ReactComponent as CoinLogo } from '../../img/icon-coin.svg';

export interface InfoItemProps {
    className?: string;
    title?: string;
    description?: string;
    isCoin?: boolean;
    loading?: boolean;
}

const InfoItem = ({ title, description, className, isCoin, loading }: InfoItemProps) => {
    return (
        <div className={className}>
            {loading ? (
                <>
                    <Skeleton height={10} width="90%" />
                    <Skeleton height={10} width="60%" />
                </>
            ) : (
                <>
                    <Typography variant="body2" component="h6" className="info-item-title" color="initial">
                        {title}
                    </Typography>
                    <Typography variant="h4" component="span" className="info-item-desc" color="initial">
                        {description}
                        {isCoin && <CoinLogo className="coin-image small white" />}
                    </Typography>
                </>
            )}
        </div>
    );
};

export default styled(InfoItem)`
    max-width: 180px;
    width: 100%;
    .info-item-title {
        opacity: 0.5;
    }
    .info-item-desc {
        display: flex;
        margin-top: 10px;
        word-break: break-word;
    }
`;
