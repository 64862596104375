import React, { useState } from 'react';
import styled from 'styled-components';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Typography, NavButton } from '../components';
import { Dot } from './components';

interface PaginationProps {
    className?: string;
    amount: number;
    activeDot: number;
}

const Pagination = ({ className, amount, activeDot }: PaginationProps) => {
    const createDots = () => {
        const dots = [];

        for (let i = 0; i < amount; i += 1) {
            dots.push(<Dot active={i === activeDot} key={i} />);
        }
        return dots.map((dot) => dot);
    };

    return (
        <div className={className}>
            <NavButton title="Prev" leftIcon={<AiOutlineLeft />} />
            <div className="dots">{createDots()}</div>
            <NavButton title="Next" rightIcon={<AiOutlineRight />} />
        </div>
    );
};

export default styled(Pagination)<PaginationProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 31px;

    .dots {
        display: flex;
        flex-direction: row;
    }
`;
