import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { MdMenu, FiSearch } from 'react-icons/all';
import { useAPI } from '../hooks';
import { MenuButton, MenuItem, Search, FullScreenMenu } from './components';
import { Container, Typography } from '../components';
import coinLogo from '../img/icon-coin.svg';

interface HeaderProps {
    className?: string;
}

type SearchType = {
    search: string;
};

const Header = ({ className }: HeaderProps) => {
    const { makeRequest } = useAPI();
    const history = useHistory();
    const location = useLocation();
    const { register, handleSubmit, watch, reset } = useForm<SearchType>();

    const searchValue = watch('search');

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const onSubmit = async (data) => {
        const { search } = data;

        if (!isNaN(search)) {
            let res = null;

            res = await makeRequest({
                method: 'height',
                value: search,
                params: {
                    hash: 'true',
                },
            });

            if (res !== null) {
                history.push(`/block/${res.hash}`);
            } else {
                history.push(`/404`);
            }
        } else if (search.length === 64) {
            let res = null;

            res = await makeRequest({
                method: 'block',
                value: search,
            });

            if (res === null) {
                history.push(`/transaction/${search}`);
            } else {
                history.push(`/block/${search}`);
            }
        } else if (search.length === 34) {
            history.push(`/address/${search}`);
        } else {
            history.push(`/404`);
        }

        setIsSearchOpen(false);
    };

    return (
        <Container className={className}>
            <div className="container">
                <div className="wrapper-nav">
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <img
                        className="coin-image large"
                        src={coinLogo}
                        alt="icon-coin"
                        onClick={() => history.push('/')}
                    />
                    <div className="nav-buttons">
                        <MenuItem type="desktop" title="Home" to="/" exact />
                        <MenuItem type="desktop" title="Tokens" to="/tokens" />
                        <MenuItem type="desktop" title="Transactions" to="/transactions" />
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Search
                        reset={reset}
                        value={searchValue}
                        submit={handleSubmit(onSubmit)}
                        register={register({ required: true })}
                        className="search"
                    />
                </form>
                <div className="btn-icons-wrapper">
                    {!isMenuOpen && (
                        <MenuButton
                            icon={<FiSearch className="toggle-icon" />}
                            isOpen={isSearchOpen}
                            setIsOpen={setIsSearchOpen}
                        />
                    )}
                    {!isSearchOpen && (
                        <MenuButton
                            icon={<MdMenu className="toggle-icon" />}
                            isOpen={isMenuOpen}
                            setIsOpen={setIsMenuOpen}
                        />
                    )}
                </div>
            </div>
            <FullScreenMenu isOpen={isMenuOpen}>
                <div className="wrapper-menu">
                    <MenuItem type="mobile" title="Home" to="/" exact onClick={closeMenu} />
                    <MenuItem type="mobile" title="Tokens" to="/tokens" onClick={closeMenu} />
                    <MenuItem type="mobile" title="Transactions" to="/transactions" onClick={closeMenu} />
                </div>
            </FullScreenMenu>
            <FullScreenMenu isOpen={isSearchOpen}>
                <div className="search-menu">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Search
                            reset={reset}
                            value={searchValue}
                            submit={handleSubmit(onSubmit)}
                            register={register({ required: true })}
                        />
                    </form>
                </div>
            </FullScreenMenu>
        </Container>
    );
};

export default styled(Header)<HeaderProps>`
    width: 100%;
    padding: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;

    .coin-image {
        cursor: pointer;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
    }

    .wrapper-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 300;
    }

    .toggle-button {
        font-size: 3rem;
        color: rgb(36, 36, 36);
        border: none;
        outline: none;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }
    .toggle-icon {
        color: ${({ theme }) => theme.palette.secondary};
        font-size: 24px;
    }

    .toggle-menu {
        position: relative;
        width: 24px;
        height: 24px;
    }

    .list {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 8vw;
        padding: 0;
        margin: 0;
        overflow: hidden;
        text-align: left;
    }

    .nav-buttons {
        display: flex;

        @media (max-width: 990px) {
            display: none;
        }
    }

    .search {
        @media (max-width: 990px) {
            display: none;
        }
    }
    .btn-icons-wrapper {
        display: flex;
        flex-direction: row;

        @media (min-width: 990px) {
            display: none;
        }
    }
    .btn-wrapper {
        margin-left: 24px;
        position: relative;
        width: 24px;
        height: 24px;
        z-index: 300;
    }

    .wrapper-menu {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        * + * {
            margin-top: 40px;
        }
    }
    .search-menu {
        overflow: hidden;
        margin-top: 90px;
        padding: 0 20px;
        height: 100%;
    }
    .centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .title {
        max-width: 450px;
        text-align: center;
    }
    .desc {
        margin-top: 10px;
    }
    .red-desc {
        color: ${({ theme }) => theme.palette.error};
    }
`;
