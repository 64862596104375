import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '.';

interface InfoBlockProps {
    className?: string;
    title: string;
    description?: string;
    active?: boolean;
    children?: any;
    loading?: boolean;
}

const InfoBlock = ({ className, title, description, active, children, loading }: InfoBlockProps) => {
    return (
        <div className={className}>
            {loading ? (
                <Skeleton height={80} className="block-info" />
            ) : (
                <div className="block-info">
                    <Typography
                        variant="body2"
                        component="h2"
                        className="title"
                        color={active ? 'lightgreen' : 'darkgray'}
                    >
                        {title}
                    </Typography>
                    {children || (
                        <Typography
                            variant="body2"
                            component="p"
                            className="description"
                            color={active ? 'initial' : 'primary'}
                        >
                            {description}
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );
};

InfoBlock.defaultProps = {
    active: false,
};

export default styled(InfoBlock)<InfoBlockProps>`
    background-color: ${({ active, loading }) => {
        if (loading) {
            return 'transparent';
        }
        if (active) {
            return '#003735';
        }
        return '#f4f4f4';
    }};
    width: 100%;
    margin-right: 4px;
    margin-bottom: 4px;

    :last-child {
        margin-right: 0px;
    }
    .block-info {
        padding: 12px;
        min-height: 80px;
        min-width: 105px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 0px;
    }
    .title {
        margin-bottom: 4px;
    }
    .description {
        font-size: ${({ active }) => (active ? '20px' : '16px')};
    }
`;
