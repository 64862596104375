import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '../components';

import { TableHeader } from './components';

interface TableProps {
    className?: string;
    withIndex?: boolean;
    data: Record<string, any>[];
    headers: {
        key: string;
        title: string;
        isMobile: boolean;
        className?: string;
        sortable?: boolean;
        href?: string;
        onClick?: (arg0: string) => void;
    }[];
}

const Table = ({ data, headers, withIndex, className }: TableProps) => {
    return (
        <div className={className}>
            <table>
                <thead className="header">
                    <tr>
                        {withIndex && <TableHeader title="#" />}
                        {headers.map((header) => (
                            <TableHeader
                                key={header.key}
                                onClick={header.onClick}
                                title={header.title}
                                sortable={header.sortable}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((el, index) => {
                        return (
                            <tr key={index}>
                                {withIndex && <td className="non-mobile">{index + 1}</td>}
                                {headers.map((header) => (
                                    <td key={header.key} className={!header.isMobile && 'non-mobile'}>
                                        {header.href ? (
                                            <Link to={header.href.replace('$text', el[header.key])} className="link">
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    color="lightblue"
                                                    className={header.className}
                                                >
                                                    {el[header.key]}
                                                </Typography>
                                            </Link>
                                        ) : (
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                color="primary"
                                                className={header.className}
                                            >
                                                {el[header.key]}
                                            </Typography>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default styled(Table)`
    table {
        border-spacing: 0;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 4px;
    }

    td {
        padding: 6px 20px;
        word-break: break-word;
    }
    tr:nth-child(odd) {
        background-color: #f4f4f4;
    }
    tr:nth-child(even) {
        background-color: #e5eaea;
    }

    @media (max-width: 414px) {
        td {
            display: table;
            padding: 3px 20px 3px 20px;
        }

        .non-mobile {
            display: none;
        }

        .header {
            display: none;
        }
    }

    .btn-element {
        cursor: pointer;
        text-decoration: underline;
        color: ${({ theme }) => theme.palette.common.lightblue};

        &:focus {
            outline: none;
        }
    }
`;
