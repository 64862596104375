import React from 'react';
import { animated, config, useTransition } from 'react-spring';
import { MdClose } from 'react-icons/all';

interface MenuButtonProps {
    icon: JSX.Element;
    isOpen: boolean;
    setIsOpen: (b: boolean) => void;
}

const MenuButton = ({ isOpen, setIsOpen, icon }: MenuButtonProps) => {
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const button = useTransition(isOpen, null, {
        from: {
            opacity: 0,
            transform: 'scale(0)',
            position: 'absolute',
        },
        enter: {
            opacity: 1,
            transform: 'scale(1)',
        },
        leave: { opacity: 0, transform: 'scale(0)' },
        config: config.stiff,
    });
    return (
        <div className="btn-wrapper">
            {button.map(({ item, key, props }) =>
                !item ? (
                    <animated.div key={key} style={props}>
                        <button type="button" className="toggle-button" onClick={toggleMenu}>
                            {icon}
                        </button>
                    </animated.div>
                ) : (
                    <animated.div key={key} style={props}>
                        <button type="button" className="toggle-button" onClick={toggleMenu}>
                            <MdClose className="toggle-icon" />
                        </button>
                    </animated.div>
                ),
            )}
        </div>
    );
};

export default MenuButton;
