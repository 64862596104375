import React from 'react';
import styled from 'styled-components';

interface FilterProps {
    changeActiveFilter: (arg0: string) => void;
    active: boolean;
    title: string;
    className?: string;
}

const Filter = ({ active, title, changeActiveFilter, className }: FilterProps) => {
    return (
        <button type="button" className={className} onClick={() => changeActiveFilter(title)}>
            {title}
        </button>
    );
};

export default styled(Filter)<FilterProps>`
    background-color: ${({ active }) => {
        return active ? '#003735' : '#e5eaea';
    }};
    color: ${({ active }) => {
        return active ? '#fff' : '#003735';
    }};

    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
`;
