import React from 'react';
import styled from 'styled-components';

interface DotProps {
    className?: string;
    active: number;
}

const Dot = ({ className, active }: DotProps) => {
    return <div className={className} />;
};

export default styled(Dot)<DotProps>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ active }) => {
        return active ? '#003735' : '#cccccc';
    }};
    margin: 0px 6px;
`;
