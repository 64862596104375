import React, { useState } from 'react';
import styled from 'styled-components';

import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';

import { Typography } from '../../components';

interface TableHeaderProps {
    className?: string;
    sortable?: boolean;
    onClick?: (arg0: string) => void;
    title: string;
}

const TableHeader = ({ className, onClick, title, sortable }: TableHeaderProps) => {
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    const changeSortOrder = () => {
        if (sortable) {
            const currentSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            setSortOrder(currentSortOrder);

            if (onClick) {
                onClick(currentSortOrder);
            }
        }
    };

    const tableHeaderTextClass = sortable ? 'tableHeaderText' : '';

    return (
        <th className={className}>
            <span role="button" tabIndex={0} onClick={changeSortOrder} className={tableHeaderTextClass}>
                <Typography variant="tooltip" component="span" color="gray">
                    {title}
                </Typography>
                <span className="sortOrder">
                    {sortable && (sortOrder === 'asc' ? <AiFillCaretUp /> : <AiFillCaretDown />)}
                </span>
            </span>
        </th>
    );
};

export default styled(TableHeader)<TableHeaderProps>`
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    text-align: left;
    padding: 6px 20px;

    .tableHeaderText:hover {
        cursor: pointer;
        user-select: none;
    }
    .sortOrder {
        vertical-align: middle;
        width: 8px;
        height: 5px;
    }
`;
