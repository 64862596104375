import React, { useState } from 'react';
import styled from 'styled-components';

import { Typography } from '.';

interface BaseButtonProps {
    className?: string;
    size: 'lg' | 'md';
    backgroundColor?: 'primary' | 'secondary' | 'initial' | string;
}

interface ButtonProps {
    className?: string;
    title: string;
    children?: any;
    size?: 'lg' | 'md';
    backgroundColor?: 'primary' | 'secondary' | 'initial' | string;
    color?: 'primary' | 'secondary' | 'initial' | string;
}

const BaseButton = styled('button')<BaseButtonProps>`
    padding: ${({ size }) => (size === 'lg' ? '10px 15px' : '5px 10px')};
    height: ${({ size }) => (size === 'lg' ? '55px' : '38px')};
    min-width: ${({ size }) => (size === 'lg' ? '320px' : '0px')};
    background-color: ${({ theme, backgroundColor }) =>
        theme.palette[backgroundColor!] ? theme.palette[backgroundColor!] : theme.palette.common[backgroundColor!]};
    border: 0;
    color: white;
    border-radius: 4px;
    transition: background-color 0.4s;

    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
        background-color: #001615;
    }
`;

const Button = ({ children, className, size, title, backgroundColor, color, ...props }: ButtonProps) => {
    return (
        <BaseButton className={className} backgroundColor={backgroundColor} size={size!} {...props}>
            {children || (
                <Typography variant={size === 'lg' ? 'button1' : 'button2'} component="span" color={color}>
                    {title}
                </Typography>
            )}
        </BaseButton>
    );
};

Button.defaultProps = {
    className: undefined,
    size: 'md',
    backgroundColor: 'secondary',
    color: 'initial',
};

export default styled(Button)<ButtonProps>``;
