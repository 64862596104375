import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns-tz';
import { useParams, useHistory } from 'react-router-dom';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { InfoPanel, TransactionCard, Loading } from '../layout';
import { Button, Container, NavButton, Typography, InfoBlock } from '../components';
import { useAPI } from '../hooks';

interface AddressDetailsProps {
    className?: string;
}

type Input = {
    address: string;
    amount: number;
    category: string;
};

type Output = {
    address: string;
    amount: number;
    category: string;
    currency: string;
    timelock: number;
};

type Transaction = {
    amount: number;
    coinbase: boolean;
    coinstake: boolean;
    fee: number;
    inputs: Input[];
    outputs: Output[];
    size: number;
    timestamp: number;
    txid: string;
    confirmations: number;
    height: number;
};

type Balance = {
    balance: number;
    locked: number;
    currency: string;
};

type Stats = {
    transactions: number;
    tokens: number;
};

const AddressDetails = ({ className }: AddressDetailsProps) => {
    const history = useHistory();
    const { address } = useParams();
    const { isLoading, makeRequest } = useAPI();
    const [transactionPage, setTransactionPage] = useState(1);
    const [addressBalance, setAddressBalance] = useState<Balance[]>([]);
    const [addressStats, setAddressStats] = useState<Stats>();
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const coinBalance = addressBalance?.find((balance: Balance) => balance.currency === 'AOK');

    const getNextTransactions = () => {
        makeRequest({
            method: 'v2/history',
            value: address,
            params: {
                page: transactionPage,
            },
        }).then((res) => {
            setTransactionPage((prev) => prev + 1);
            setTransactions((prev) => [...prev, ...res]);
        });
    };

    useEffect(() => {
        setTransactionPage(1);
        setAddressBalance([]);
        setAddressStats(undefined);
        setTransactions([]);

        makeRequest({
            method: 'v2/balance',
            value: address,
        }).then((res: Balance[]) => {
            if (res && res.length > 0) {
                const coin = res.find((balance: Balance) => balance.currency === 'AOK');

                if (coin) {
                    setAddressBalance(res);
                } else {
                    setAddressBalance([
                        ...res,
                        {
                            balance: 0,
                            locked: 0,
                            currency: 'AOK',
                        },
                    ]);
                }
            } else {
                setAddressBalance([
                    {
                        balance: 0,
                        locked: 0,
                        currency: 'AOK',
                    },
                ]);
            }
        });

        makeRequest({
            method: 'v2/stats',
            value: address,
        }).then((stats) => {
            setAddressStats(stats);
        });

        getNextTransactions();
    }, [address]);

    return (
        <>
            <Helmet>
                <title>Address {address}</title>
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography component="h1" color="primary">
                        Address
                    </Typography>
                </div>
                <InfoPanel
                    loading={!(addressStats && coinBalance)}
                    title="Address"
                    description={address}
                    data={[
                        {
                            title: 'Balance',
                            description: coinBalance ? Number(coinBalance.balance).toFixed(2) : (0).toFixed(2),
                            isCoin: true,
                        },
                        {
                            title: 'Locked Balance',
                            description: coinBalance ? Number(coinBalance.locked).toFixed(2) : (0).toFixed(2),
                            isCoin: true,
                        },
                        { title: 'Tokens Held', description: String(addressStats?.tokens) },
                        { title: 'Transactions', description: String(addressStats?.transactions) },
                    ]}
                    qrData={address}
                />
                {addressBalance.length > 1 && (
                    <div id="info-blocks">
                        {addressBalance.map((balance: Balance) =>
                            balance.currency !== 'AOK' ? (
                                <InfoBlock
                                    key={balance.currency}
                                    loading={!addressStats && addressBalance.length === 0}
                                    title={balance.currency}
                                    description={`${balance.balance} ${balance.currency}`}
                                    className="common-block"
                                />
                            ) : null,
                        )}
                    </div>
                )}
                {history.length > 2 && (
                    <NavButton
                        leftIcon={<AiOutlineLeft />}
                        onClick={() => history.goBack()}
                        className="nav-button"
                        title="Back"
                    />
                )}
                {transactions.length > 0 &&
                    transactions.map((transaction) => (
                        <TransactionCard
                            date={format(new Date(transaction.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss')}
                            hash={transaction.txid}
                            from={transaction.inputs}
                            to={transaction.outputs}
                            coinbase={transaction.coinbase}
                            coinstake={transaction.coinstake}
                            key={transaction.txid}
                            fee={transaction.fee}
                        />
                    ))}

                {isLoading && <Loading />}

                {!isLoading && addressBalance && addressStats && transactions.length < addressStats.transactions && (
                    <Button
                        className="btn"
                        backgroundColor="secondary"
                        size="lg"
                        title="Load more"
                        onClick={getNextTransactions}
                    />
                )}
            </Container>
        </>
    );
};

export default styled(AddressDetails)`
    flex-direction: column;
    display: flex;

    .nav-button {
        margin: 22px 0;
    }
    .subtitle {
        display: block;
        margin-bottom: 20px;
    }
    .btn {
        margin: 20px 0;
        align-self: center;
    }
    #info-blocks {
        margin-top: 4px;
        display: flex;
        flex-wrap: wrap;

        .common-block {
            flex: 1;
            min-width: 200px;
        }
    }
`;
