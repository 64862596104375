import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Container, Typography, InfoBlock } from '../components';

interface StatusProps {
    className?: string;
}

const Status = ({ className }: StatusProps) => {
    return (
        <>
            <Helmet>
                <title>Status Overview</title>
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography variant="h1" component="h1">
                        Status Overview
                    </Typography>
                </div>
                <div className="information">
                    <div className="subtitle">
                        <Typography variant="h4" component="h3" color="secondary">
                            Tokens Overview
                        </Typography>
                    </div>
                    <div className="info-blocks">
                        <InfoBlock className="infoblock-lg" title="Total Tokens" active description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Last Block" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Sync Progress" description="NaN" />
                    </div>
                </div>
                <div className="information">
                    <div className="subtitle">
                        <Typography variant="h4" component="h3" color="secondary">
                            AOK Economics
                        </Typography>
                    </div>
                    <div className="info-blocks">
                        <InfoBlock className="infoblock-sm" title="Mined" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Circulation" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Burn - Total" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Burn - Token Issue" description="NaN" />
                    </div>
                    <div className="info-blocks">
                        <InfoBlock className="infoblock-sm" title="Burn - Subasset Issue" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Burn - Unique Token Issue" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Burn - Token Reissue" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Burn - Tokens" description="NaN" />
                    </div>
                </div>
                <div className="information">
                    <div className="subtitle">
                        <Typography variant="h4" component="h3" color="secondary">
                            Network Overview
                        </Typography>
                    </div>
                    <div className="info-blocks">
                        <InfoBlock className="infoblock-sm" title="Chain" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Version" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Sub Version" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Protocol Version" description="NaN" />
                    </div>
                    <div className="info-blocks">
                        <InfoBlock className="infoblock-lg" title="Connections" description="NaN" />
                        <InfoBlock className="infoblock-lg" title="Best Block Hash" description="NaN" />
                    </div>
                    <div className="info-blocks">
                        <InfoBlock className="infoblock-sm" title="Blocks" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Headers" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Difficulty" description="NaN" />
                        <InfoBlock className="infoblock-sm" title="Verification Progress" description="NaN" />
                    </div>
                </div>
            </Container>
        </>
    );
};

export default styled(Status)<StatusProps>`
    flex-direction: column;
    display: flex;

    .info-blocks {
        display: flex;
        @media (max-width: 750px) {
            flex-wrap: wrap;
        }
    }
    .active-block {
        flex: 200%;
    }
    .information {
        margin-top: 35px;

        .subtitle {
            margin-bottom: 10px;
        }
    }
    .infoblock-sm {
        @media (max-width: 750px) {
            width: calc(50% - 4px);
        }
    }
    .infoblock-lg {
        @media (max-width: 750px) {
            width: 100%;
        }
    }
`;
