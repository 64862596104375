import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Button, Container, Typography } from '../components';

interface Page404Props {
    className?: string;
}

const Page404 = ({ className }: Page404Props) => {
    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>Not Found</title>
            </Helmet>
            <div className={className}>
                <Typography className="title" component="h1" fontSize="36px" color="secondary">
                    Sorry, the page was not found.
                </Typography>
                <Typography className="desc" variant="button2" component="span">
                    Return to the previous page or check your search request twice.
                </Typography>
                <Button
                    className="btn"
                    backgroundColor="secondary"
                    size="lg"
                    title="Return to previous page"
                    onClick={() => history.goBack()}
                />
            </div>
        </>
    );
};

export default styled(Page404)`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    .title {
        max-width: 450px;
        text-align: center;
    }
    .desc {
        margin-top: 10px;
    }
    .red-desc {
        color: ${({ theme }) => theme.palette.error};
    }
    .btn {
        margin-top: 40px;
    }
`;
