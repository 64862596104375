import React from 'react';
import Loader from 'react-loaders';
import styled from 'styled-components';
import { Container } from '../components';
import 'loaders.css/loaders.min.css';

interface LoadingProps {
    className?: string;
}

function Loading({ className }: LoadingProps) {
    return (
        <Container className={className}>
            <Loader type="ball-clip-rotate" active innerClassName="loading" />
        </Container>
    );
}

export default styled(Loading)`
    display: flex;
    justify-content: center;
    align-items: center;

    .loading {
        div {
            border-color: ${({ theme }) => theme.palette.common.gray};
            border-bottom-color: transparent;
        }
    }
`;
