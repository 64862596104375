import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useHistory, Link, useParams } from 'react-router-dom';
import { AiFillSketchCircle } from 'react-icons/ai';
import { format } from 'date-fns-tz';
import { useAPI } from '../hooks';
import { Container, Typography, InfoBlock, Button, Chart } from '../components';
import { Table, Loading } from '../layout';
import { ReactComponent as CoinLogo } from '../img/icon-coin.svg';

interface RichListProps {
    className?: string;
}

type Address = {
    address: string;
    balance: number;
};

const RichList = ({ className }: RichListProps) => {
    const history = useHistory();
    const { token } = useParams();
    const { isLoading, makeRequest } = useAPI();
    const [page, setPage] = useState<number>(1);
    const [addresses, setAddresses] = useState<Address[]>([]);

    const getNextPage = (refresh = false) => {
        makeRequest({
            method: 'v2/richlist',
            value: token ? token.toUpperCase() : undefined,
            params: {
                page: refresh ? 1 : page,
            },
        }).then((res) => {
            if (refresh) {
                setAddresses((prev) =>
                    [...res, ...prev].filter((value, index, self) => {
                        return self.findIndex((v) => v.address === value.address) === index;
                    }),
                );
            } else {
                setPage((prev) => prev + 1);
                setAddresses((prev) =>
                    [...prev, ...res].filter((value, index, self) => {
                        return self.findIndex((v) => v.address === value.address) === index;
                    }),
                );
            }
        });
    };

    useEffect(() => {
        getNextPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Holders</title>
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography variant="h1" component="h1">
                        {token ? token.toUpperCase() : 'AOK'} Holders
                    </Typography>
                </div>
                {addresses.length > 0 && (
                    <div className="table-wrapper">
                        <Table
                            className="table"
                            withIndex
                            data={addresses}
                            headers={[
                                {
                                    key: 'address',
                                    title: 'Address',
                                    className: 'mono-font',
                                    sortable: false,
                                    isMobile: true,
                                    href: '/address/$text',
                                },
                                {
                                    key: 'balance',
                                    title: 'Balance',
                                    sortable: false,
                                    isMobile: true,
                                },
                            ]}
                        />
                    </div>
                )}

                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="button-load">
                        <Button backgroundColor="secondary" size="lg" title="Load more" onClick={() => getNextPage()} />
                    </div>
                )}
            </Container>
        </>
    );
};

export default styled(RichList)<RichListProps>`
    flex-direction: column;
    display: flex;

    .icon {
        font-size: 16px;
        margin-left: 4px;
        line-heigth: 20px;
    }
    .description {
        display: flex;
        align-items: center;
    }
    #info-blocks {
        display: flex;
        flex-wrap: wrap;
        .active-block {
            flex: 2;
        }

        .common-block {
            flex: 1;
        }
    }
    .table-wrapper {
        margin-bottom: 20px;
    }
    .button-load {
        display: flex;
        justify-content: center;
    }
`;
