import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Typography } from '../../components';

interface MenuItemProps {
    title: string;
    active?: boolean;
    className?: string;
    type?: 'desktop' | 'mobile';
    to: string;
}

const MenuItem = ({ title, type, className, ...props }: MenuItemProps) => {
    return (
        <NavLink className={className} activeClassName={`active-nav ${type}`} {...props}>
            <Typography textTransform="uppercase" className="item-text" component="p" variant="h4" fontWeight={600}>
                {title}
            </Typography>
        </NavLink>
    );
};

export default styled(MenuItem)<MenuItemProps>`
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) => theme.palette.initial};
    margin: ${({ type }) => (type === 'mobile' ? '0' : '0 50px 0 0')};
    text-decoration: none;
`;
