import { createGlobalStyle } from 'styled-components';
import { Palette, Typography } from '../types';

interface Theme {
    palette: Palette;
    typography: Typography;
}

declare module 'styled-components' {
    // eslint-disable-next-line
    export interface DefaultTheme extends Theme {}
}

const GlobalStyle = createGlobalStyle`
    * {       
        box-sizing: border-box; 
    }

    html {
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-size:  ${({ theme }) => theme.typography.fontSize};
        height: 100%;
    }

    body {
        background-color: ${({ theme }) => theme.palette.background};
        margin: 0;
        height: 100%;
        -webkit-text-size-adjust: 100%;
    }

    #root {
        height: 100%;
        margin-top: 80px;
    }

    .header {
        margin: 20px 0;
    }

    .active-nav.desktop {
        border-bottom-color: ${({ theme }) => theme.palette.primary};
        color: ${({ theme }) => theme.palette.secondary};
        opacity: 1;
    }

    .active-nav.mobile {
        color: ${({ theme }) => theme.palette.secondary};
        opacity: 0.3;
    }

    .coin-image {
        &.large {
            width: 32px;
            height: 32px;
            margin-right: 60px;
        }
        &.small {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
        &.bottom-fix {
            margin-bottom: 2px;
        }
        &.white {
            path {
                fill: white;
            }
        }
    }

    .mono-font {
        font-family: 'Roboto Mono';
    }

    .react-responsive-modal-container {
        padding: 20px;
        overflow-y: hidden;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal {
        max-width: 900px;
        width: 100%;
        max-height: 700px;
        height: 100%;
    }
    
    .root-notistack {
       #notistack-snackbar {
           font-size: 14px;
      }
    }

    .link {
        text-decoration: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
    
    .recharts-cartesian-axis-tick {    
        // font-family: ${({ theme }) => theme.typography.fontFamily};
    }
`;

export default GlobalStyle;
