import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface ContainerProps {
    id?: string;
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const Container = forwardRef<HTMLInputElement, ContainerProps>(({ id, className, children }: ContainerProps, ref) => {
    return (
        <div ref={ref} id={id} className={className}>
            {children}
        </div>
    );
});

Container.defaultProps = {
    className: undefined,
};

export default styled(Container)<ContainerProps>`
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 750px;
    width: 100%;

    @media (min-width: 992px) {
        max-width: 970px;
    }

    @media (min-width: 1200px) {
        max-width: 1170px;
    }
`;
