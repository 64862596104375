import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useAPI } from '../hooks';
import { Container, Typography, Filter } from '../components';
import { Table, Pagination, Loading } from '../layout';

interface TokensProps {
    className?: string;
}

const tableHeaders = [
    {
        key: 'name',
        title: 'Token',
        sortable: false,
        href: 'token/$text',
        isMobile: true,
    },
    {
        key: 'blockhash',
        title: 'Blockhash',
        className: 'mono-font',
        href: '/block/$text',
        sortable: false,
        isMobile: true,
    },
    {
        key: 'units',
        title: 'Units',
        sortable: false,
    },
    {
        key: 'block_height',
        title: 'Height',
        sortable: false,
    },
];

type TokensType = Record<
    string,
    {
        name: string;
        amount: number;
        units: number;
        reissuable: number;
        block_height: number;
        blockhash: string;
    }
>;

const Tokens = ({ className }: TokensProps) => {
    const [activeFilter, setActiveFilter] = useState<string>('All');
    const { isLoading, makeRequest } = useAPI();
    const [tokens, setTokens] = useState<TokensType>();

    const changeActiveFilter = (filter) => {
        setActiveFilter(filter);
    };

    const createFilters = () => {
        const filters = ['All'];

        for (let i = 0; i < 26; i += 1) {
            filters.push((i + 10).toString(36).toUpperCase());
        }

        return filters.map((filter) => (
            <Filter
                className="filter-item"
                active={filter === activeFilter}
                title={filter}
                key={filter}
                changeActiveFilter={changeActiveFilter}
            />
        ));
    };

    useEffect(() => {
        setTokens(undefined);

        makeRequest({
            method: 'tokens',
            params: {
                search: activeFilter !== 'All' ? activeFilter.toUpperCase() : '',
            },
        }).then((res) => {
            setTokens(res);
        });
    }, [activeFilter]);

    return (
        <>
            <Helmet>
                <title>Tokens</title>
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography variant="h1" component="h1">
                        Tokens
                    </Typography>
                </div>
                <div className="filters">{createFilters()}</div>
                <div className="filter">
                    <Typography variant="h2" component="h2">
                        {activeFilter.toUpperCase()}
                    </Typography>
                </div>
                {tokens ? (
                    <div className="table">
                        <Table data={Object.values(tokens)} headers={tableHeaders} />
                    </div>
                ) : (
                    <Loading />
                )}
            </Container>
        </>
    );
};

export default styled(Tokens)<TokensProps>`
    flex-direction: column;
    display: flex;

    .filters {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .filters::-webkit-scrollbar {
        display: none;
    }

    .title {
        margin-bottom: 6px;
        margin-top: 56px;
    }

    .filter-item {
        margin-right: 7px;
        flex: 0 0 auto;
    }

    .filter {
        margin-top: 40px;
    }
`;
