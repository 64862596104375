import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { AiFillSketchCircle } from 'react-icons/ai';
import { format } from 'date-fns-tz';
import { useAPI } from '../hooks';
import { Container, Typography, InfoBlock, Button } from '../components';
import { Table, Loading } from '../layout';
import { ReactComponent as CoinLogo } from '../img/icon-coin.svg';

interface MempoolProps {
    className?: string;
}

type Transaction = {
    bits: string;
    chainwork: string;
    confirmations: number;
    difficulty: number;
    flags: string;
    hash: string;
    height: number;
    mediantime: number;
    merkleroot: string;
    modifier: string;
    nethash: number;
    nonce: number;
    previousblockhash: string;
    signature: string;
    size: number;
    strippedsize: number;
    time: number;
    tx: string[];
    txcount: number;
    version: number;
    versionHex: string;
    weight: number;
};

type MempoolType = {
    size: number;
    bytes: number;
    usage: number;
    maxmempool: number;
    mempoolminfee: number;
    tx: Transaction[];
};

const Mempool = ({ className }: MempoolProps) => {
    const history = useHistory();
    const { isLoading, makeRequest } = useAPI();
    const [mempool, setMempool] = useState<MempoolType>();

    useEffect(() => {
        makeRequest({
            method: 'mempool',
        }).then((res) => {
            setMempool(res);
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Mempool</title>
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography variant="h1" component="h1">
                        Mempool
                    </Typography>
                </div>
                <div id="info-blocks">
                    <InfoBlock
                        loading={!mempool}
                        title="Pending"
                        description={String(mempool?.tx?.length)}
                        className="common-block"
                        active
                    />
                    <InfoBlock
                        loading={!mempool}
                        title="Max Mempool"
                        description={String(mempool?.maxmempool)}
                        className="common-block"
                    />
                    <InfoBlock
                        loading={!mempool}
                        title="Usage"
                        description={String(mempool?.usage)}
                        className="common-block"
                    />
                </div>
                {mempool && mempool.tx.length > 0 && (
                    <div className="table-wrapper">
                        <Table
                            className="table"
                            data={mempool.tx.map((tx) => ({ hash: tx }))}
                            headers={[
                                {
                                    key: 'hash',
                                    title: 'Transaction Hash',
                                    className: 'mono-font',
                                    isMobile: true,
                                    sortable: false,
                                    href: '/transaction/$text',
                                },
                            ]}
                        />
                    </div>
                )}

                {isLoading && <Loading />}
            </Container>
        </>
    );
};

export default styled(Mempool)<MempoolProps>`
    flex-direction: column;
    display: flex;

    .icon {
        font-size: 16px;
        margin-left: 4px;
        line-heigth: 20px;
    }
    .description {
        display: flex;
        align-items: center;
    }
    #info-blocks {
        display: flex;
        flex-wrap: wrap;
        .active-block {
            flex: 2;
        }

        .common-block {
            flex: 1;
        }
    }
    .table-wrapper {
        margin: 20px 0;
    }
    .button-load {
        display: flex;
        justify-content: center;
    }
`;
