import React from 'react';

import { MdContentCopy, BsFillLockFill } from 'react-icons/all';

import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { format } from 'date-fns-tz';
import { AmountInfo } from '../../types';
import { Typography } from '../../components';
import coinLogo from '../../img/icon-coin.svg';

const AmountInformation = ({
    background,
    color,
    source,
    type,
    toClipboard,
    amount,
    timelock,
    coin,
    className,
}: AmountInfo) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onCopy = () => {
        enqueueSnackbar(`${type} has been successfully copied to clipboard.`);
    };
    return (
        <div className={className}>
            <div className="source-wrapper">
                {type === 'Address' && (
                    <Link to={`/address/${source}`} className="text link">
                        <Typography variant="button2" component="h4" color="gray">
                            {source}
                        </Typography>
                    </Link>
                )}
                {type !== 'Address' && (
                    <Typography variant="button2" component="h4" color="gray" className="text">
                        {source}
                    </Typography>
                )}
                {toClipboard && (
                    <CopyToClipboard onCopy={onCopy} text={source}>
                        <MdContentCopy className="icon-copy" />
                    </CopyToClipboard>
                )}
            </div>
            <div className="amount-wrapper">
                <Typography variant="h3" component="h5" color="secondary" className="text">
                    {amount} {coin && coin.toUpperCase()}
                </Typography>
                {!coin && <img className="coin-image small" src={coinLogo} alt="icon-coin" />}
                {timelock && timelock > 0 ? (
                    <div className="lockup">
                        <BsFillLockFill className="lockup-icon" size={16} />
                        <Typography color="lightred" variant="button1" fontWeight={800} component="span">
                            {timelock > 500000 ? format(new Date(timelock * 1000), 'yyyy-MM-dd HH:mm:ss') : timelock}
                        </Typography>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

AmountInformation.defaultProps = {
    toClipboard: false,
    color: 'secondary',
    background: false,
};

export default styled(AmountInformation)`
    background-color: ${({ theme, color, background }) =>
        background && color ? theme.palette.common[color] : 'transparent'};
    margin-top: 4px;
    padding: ${({ background }) => (background ? '5px 12px' : '0')};
    .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .source-wrapper {
        align-items: center;
        display: flex;
    }
    .icon-copy {
        margin-left: 4px;
        cursor: pointer;
        color: ${({ theme }) => theme.palette.secondary};
    }
    .amount-wrapper {
        margin-top: 4px;
        display: flex;
        align-items: center;
    }
    .lockup {
        display: flex;
        align-items: center;
    }
    .lockup-icon {
        margin-left: 12px;
        margin-right: 4px;
        color: ${({ theme }) => theme.palette.common.lightred};
    }
`;
