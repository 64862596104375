import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns-tz';
import { useParams, useHistory } from 'react-router-dom';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import Skeleton from 'react-loading-skeleton';
import { InfoPanel, TransactionCard, Loading } from '../layout';
import { Button, Container, NavButton, Typography } from '../components';
import { useAPI, useUtils } from '../hooks';

interface BlockDetailsProps {
    className?: string;
}

type Input = {
    address: string;
    amount: number;
    category: string;
};

type Output = {
    address: string;
    amount: number;
    category: string;
    currency: string;
    timelock: number;
};

type Transaction = {
    amount: number;
    coinbase: boolean;
    coinstake: boolean;
    fee: number;
    inputs: Input[];
    outputs: Output[];
    size: number;
    timestamp: number;
    txid: string;
    confirmations: number;
    height: number;
};

type Block = {
    bits: string;
    blockhash: string;
    chainwork: string;
    difficulty: number;
    height: number;
    merkleroot: string;
    nonce: number;
    reward: number;
    signature: string;
    size: number;
    stake: boolean;
    timestamp: number;
    tx: number;
    version: number;
    weight: number;
};

const BlockDetails = ({ className }: BlockDetailsProps) => {
    const history = useHistory();
    const { hash } = useParams();
    const { isLoading, makeRequest } = useAPI();
    const { blockReward, getStatus } = useUtils();
    const [transactionPage, setTransactionPage] = useState(1);
    const [block, setBlock] = useState<Block>();
    const [transactions, setTransactions] = useState<Transaction[]>([]);

    const getNextTransactions = () => {
        makeRequest({
            method: `v2/block/${block.blockhash}/transactions`,
            params: {
                page: transactionPage,
            },
        }).then((res) => {
            setTransactionPage((prev) => prev + 1);
            setTransactions((prev) => [...prev, ...res]);
        });
    };

    useEffect(() => {
        if (block) {
            getNextTransactions();
        }
    }, [block]);

    useEffect(() => {
        setTransactionPage(1);
        setBlock(undefined);
        setTransactions([]);

        makeRequest({
            method: 'v2/block',
            value: hash,
        }).then((res) => {
            setBlock(res);
        });
    }, [hash]);

    return (
        <>
            <Helmet>
                <title>Block {hash}</title>
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography component="h1" color="primary">
                        Block
                    </Typography>
                </div>
                <InfoPanel
                    loading={!block}
                    title="Block hash"
                    description={block?.blockhash}
                    data={[
                        { title: 'Transactions', description: String(block?.tx) },
                        { title: 'Height', description: String(block?.height) },
                        { title: 'Reward', description: String(block?.reward.toFixed(2)), isCoin: true },
                        { title: 'Size', description: String(block?.size) },
                        { title: 'Difficulty', description: String(block?.difficulty.toFixed(2)) },
                    ]}
                    qrData={block?.blockhash}
                />
                {history.length > 2 && (
                    <NavButton
                        leftIcon={<AiOutlineLeft />}
                        onClick={() => history.goBack()}
                        className="nav-button"
                        title="Back"
                    />
                )}
                {transactions.length > 0 &&
                    transactions.map((transaction) => (
                        <TransactionCard
                            date={format(new Date(transaction.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss')}
                            hash={transaction.txid}
                            from={transaction.inputs}
                            to={transaction.outputs}
                            coinbase={transaction.coinbase}
                            coinstake={transaction.coinstake}
                            key={transaction.txid}
                            fee={transaction.fee}
                        />
                    ))}

                {isLoading && <Loading />}

                {!isLoading && block && block.tx > transactions.length && (
                    <Button
                        className="btn"
                        backgroundColor="secondary"
                        size="lg"
                        title="Load more"
                        onClick={getNextTransactions}
                    />
                )}
            </Container>
        </>
    );
};

export default styled(BlockDetails)`
    flex-direction: column;
    display: flex;

    .nav-button {
        margin: 22px 0;
    }
    .subtitle {
        display: block;
        margin-bottom: 20px;
    }
    .btn {
        margin: 20px 0;
        align-self: center;
    }
`;
