import React from 'react';
import styled from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdClose } from 'react-icons/all';

interface SearchProps {
    className?: string;
    register: (ref: any) => void;
    submit: (data: any) => void;
    value?: string;
    reset: () => void;
}

const Search = ({ submit, className, register, value, reset }: SearchProps) => {
    return (
        <div className={className}>
            <input ref={register} name="search" placeholder="Search for Height or Hash" type="text" id="search" />
            <div className="icons-wrapper">
                {value && <MdClose id="toggle-icon" onClick={reset} />}
                <AiOutlineSearch id="search-icon" onClick={submit} />
            </div>
        </div>
    );
};

export default styled(Search)<SearchProps>`
    min-height: 40px;
    min-width: 300px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f5f5f5;

    #search {
        width: 100%;
        background-color: transparent;
        border: none;
        font-size: 14px;
    }

    #search:focus {
        outline: none;
    }

    #search-icon {
        font-size: 20px;
        cursor: pointer;
    }

    #toggle-icon {
        font-size: 20px;
        cursor: pointer;
    }
    .icons-wrapper {
        display: flex;
    }
`;
