import React, { useState, useEffect } from 'react';

type Status = 'default' | 'coinstake' | 'reissue' | 'issue' | 'transfer' | 'coinbase';

const useUtils = () => {
    const getStatus = (transaction) => {
        const tokenTypes = ['transfer_token', 'new_token', 'reissue_token'];
        let status: Status = 'default';

        if (!transaction) {
            return undefined;
        }

        transaction.vin.forEach(function (vin, index) {
            if (vin.coinbase !== undefined) {
                status = 'coinbase';
            }
        });

        transaction.vout.forEach(function (vout, index) {
            if (vout.value !== undefined) {
                if (
                    index === 0 &&
                    vout.value === 0 &&
                    status !== 'coinbase' &&
                    !tokenTypes.includes(vout.scriptPubKey.type)
                ) {
                    status = 'coinstake';
                } else if (tokenTypes.includes(vout.scriptPubKey.type)) {
                    if (vout.scriptPubKey.type === 'transfer_token') {
                        status = 'transfer';
                    }

                    if (vout.scriptPubKey.type === 'reissue_token') {
                        status = 'reissue';
                    }

                    if (vout.scriptPubKey.type === 'new_token') {
                        status = 'issue';
                    }
                }
            }
        });

        return status;
    };

    const getStatusTitle = (status) => {
        switch (status) {
            case 'coinstake':
                return 'Coinstake';
            case 'reissue':
                return 'Token Reissue';
            case 'issue':
                return 'New Token';
            case 'transfer':
                return 'Token Transfer';
            case 'coinbase':
                return 'Coinbase';
            default:
                return undefined;
        }
    };

    const blockReward = (height) => {
        let halvings = Math.floor(height / 525960);

        if (halvings > 10) {
            halvings = 0;
        }

        return 4 >> halvings;
    };

    return { getStatus, getStatusTitle, blockReward };
};

export default useUtils;
