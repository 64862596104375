import React from 'react';
import styled from 'styled-components';
import { Typography } from '.';

interface ButtonProps {
    className?: string;
    title: string;
    leftIcon?: React.ReactElement;
    rightIcon?: React.ReactElement;
}

const Button = ({ className, title, leftIcon, rightIcon, ...props }: ButtonProps) => {
    return (
        <button type="button" className={className} {...props}>
            <div className="left-icon">{leftIcon}</div>
            <Typography variant="body1" component="span">
                {title}
            </Typography>
            <div className="right-icon">{rightIcon}</div>
        </button>
    );
};

export default styled(Button)<ButtonProps>`
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;

    .left-icon,
    .right-icon {
        display: flex;
        align-items: center;
    }
    .left-icon {
        margin-right: 5px;
    }
    .right-icon {
        margin-left: 5px;
    }
    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
`;
