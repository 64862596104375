import React, { Fragment } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '../providers';
import { Snackbar, ScrollToTop } from '../components';
import { Footer, Header } from '../layout';
import {
    Tokens,
    TokenTransactions,
    Transactions,
    Mempool,
    Status,
    TransactionDetails,
    BlockDetails,
    AddressDetails,
    Page404,
    Home,
    RichList,
} from '../pages';

const App = () => {
    const history = useHistory();

    if (location.hash.startsWith('#/')) {
        history.push(location.hash.replace('#', '')); // or history.replace
    }

    return (
        <ThemeProvider mode="light">
            <ScrollToTop />
            <SnackbarProvider
                classes={{
                    root: 'root-notistack',
                }}
                content={(key, message) => <Snackbar id={key} message={message} />}
            >
                <Helmet defaultTitle="AOK Explorer" titleTemplate="%s | AOK Explorer" />
                <Header />
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/transactions" exact>
                        <Transactions />
                    </Route>
                    <Route path="/tokens" exact>
                        <Tokens />
                    </Route>
                    <Route path="/token/:token" exact>
                        <TokenTransactions />
                    </Route>
                    <Route path="/mempool" exact>
                        <Mempool />
                    </Route>
                    <Route path="/transaction/:hash" exact>
                        <TransactionDetails />
                    </Route>
                    <Route path="/block/:hash" exact>
                        <BlockDetails />
                    </Route>
                    <Route path="/address/:address" exact>
                        <AddressDetails />
                    </Route>
                    <Route path="/holders" exact>
                        <RichList />
                    </Route>
                    <Route path="/holders/:token" exact>
                        <RichList />
                    </Route>
                    <Route component={Page404} />
                </Switch>
                <Footer />
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
