import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { AiFillSketchCircle } from 'react-icons/ai';
import { format } from 'date-fns-tz';
import { useAPI } from '../hooks';
import { Container, Typography, InfoBlock, Button, Chart } from '../components';
import { Table, Loading } from '../layout';
import { ReactComponent as CoinLogo } from '../img/icon-coin.svg';

interface HomeProps {
    className?: string;
}

type Info = {
    blockhash: string;
    height: number;
    chainwork: string;
    difficulty: number;
    time: number;
    reward: number;
};

type Block = {
    tx: number;
    blockhash: string;
    timestamp: number;
    height: number;
};

type MempoolType = {
    size: number;
    bytes: number;
    usage: number;
    maxmempool: number;
    mempoolminfee: number;
    tx: Transaction[];
};

const Home = ({ className }: HomeProps) => {
    const history = useHistory();
    const containerRef = useRef<HTMLDivElement>(null);
    const { isLoading, makeRequest } = useAPI();
    const [info, setInfo] = useState<Info>();
    const [chart, setChart] = useState();
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [page, setPage] = useState<number>(1);
    const [blocks, setBlocks] = useState<Block[]>([]);
    const [mempool, setMempool] = useState<MempoolType>();

    const getNextPage = (refresh = false) => {
        makeRequest({
            method: 'v2/blocks',
            params: {
                page: refresh ? 1 : page,
            },
        }).then((res) => {
            if (refresh) {
                setBlocks((prev) =>
                    [
                        ...res.map((tx) => ({
                            ...tx,
                            timestamp: format(new Date(tx.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss'),
                        })),
                        ...prev,
                    ].filter((value, index, self) => {
                        return self.findIndex((v) => v.blockhash === value.blockhash) === index;
                    }),
                );
            } else {
                setPage((prev) => prev + 1);
                setBlocks((prev) =>
                    [
                        ...prev,
                        ...res.map((tx) => ({
                            ...tx,
                            timestamp: format(new Date(tx.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss'),
                        })),
                    ].filter((value, index, self) => {
                        return self.findIndex((v) => v.blockhash === value.blockhash) === index;
                    }),
                );
            }
        });
    };

    const update = () => {
        makeRequest({
            method: 'v2/latest',
        }).then((res) => {
            setInfo(res);
        });
    };

    useEffect(() => {
        update();
        const interval = setInterval(update, 60000);

        makeRequest({
            method: 'mempool',
        }).then((res) => {
            setMempool(res);
        });

        makeRequest({
            method: 'v2/chart',
        }).then((res) => {
            const transactions = Object.values(res);
            const formatedChart = [];
            let total = 0;

            Object.keys(res).forEach((height, index) => {
                total += transactions[index];

                if (index % 10 === 0) {
                    formatedChart.push({
                        name: height,
                        value: Number(transactions[index]),
                    });
                } else {
                    formatedChart[formatedChart.length - 1] = {
                        ...formatedChart[formatedChart.length - 1],
                        value: formatedChart[formatedChart.length - 1].value + Number(transactions[index]),
                    };
                }
            });

            setTotalTransactions(total);
            setChart(formatedChart);
        });

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (info) {
            getNextPage();
        }
    }, [info]);

    return (
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Container ref={containerRef} className={className}>
                <div className="header">
                    <Typography variant="h1" component="h1">
                        AOK Explorer
                    </Typography>
                </div>
                <div id="info-blocks">
                    <InfoBlock
                        loading={!totalTransactions}
                        title="Total Transactions (24h)"
                        description={totalTransactions}
                        className="active-block"
                        active
                    />
                    <InfoBlock
                        loading={!info}
                        title="Difficulty"
                        description={String(info?.difficulty.toFixed(2))}
                        className="common-block"
                    />
                    <InfoBlock
                        loading={!info}
                        title="Height"
                        description={String(info?.height)}
                        className="common-block"
                    />
                    <InfoBlock loading={!mempool} title="Mempool" className="common-block">
                        <Link to="/mempool" className="link">
                            <Typography variant="body1" component="span" color="lightblue" className="description">
                                {mempool?.tx?.length}
                            </Typography>
                        </Link>
                    </InfoBlock>
                    <InfoBlock loading={!info} title="Reward" className="common-block">
                        <Typography variant="body1" component="span" color="primary" className="description">
                            {String(info?.reward.toFixed(2))}
                            <CoinLogo className="coin-image small bottom-fix" />
                        </Typography>
                    </InfoBlock>
                </div>
                <Chart width="100%" height={300} data={chart} loading={!chart} />
                {blocks.length > 0 && (
                    <div className="table-wrapper">
                        <Table
                            className="table"
                            data={blocks}
                            headers={[
                                {
                                    key: 'height',
                                    title: 'Height',
                                    sortable: false,
                                    isMobile: true,
                                },
                                {
                                    key: 'blockhash',
                                    title: 'Block Hash',
                                    className: 'mono-font',
                                    sortable: false,
                                    isMobile: true,
                                    href: '/block/$text',
                                },
                                {
                                    key: 'tx',
                                    title: 'Transactions',
                                    sortable: false,
                                },
                                {
                                    key: 'timestamp',
                                    title: 'Date & time',
                                    sortable: false,
                                    isMobile: true,
                                },
                            ]}
                        />
                    </div>
                )}

                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="button-load">
                        <Button backgroundColor="secondary" size="lg" title="Load more" onClick={() => getNextPage()} />
                    </div>
                )}
            </Container>
        </>
    );
};

export default styled(Home)<HomeProps>`
    flex-direction: column;
    display: flex;

    .icon {
        font-size: 16px;
        margin-left: 4px;
        line-heigth: 20px;
    }
    .description {
        display: flex;
        align-items: center;
    }
    #info-blocks {
        display: flex;
        flex-wrap: wrap;
        .active-block {
            flex: 2;
        }

        .common-block {
            flex: 1;
        }
    }
    .table-wrapper {
        margin-bottom: 20px;
    }
    .button-load {
        display: flex;
        justify-content: center;
    }
`;
