import React from 'react';
import {
    Area,
    AreaChart,
    Line,
    CartesianGrid,
    XAxis,
    Legend,
    Tooltip,
    Label,
    ResponsiveContainer,
    Brush,
} from 'recharts';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Loading } from '../layout';

interface ChartProps {
    width?: number;
    className?: string;
    loading?: boolean;
}

const Chart = ({ loading, width, className, ...props }: ChartProps) => {
    return (
        <div className={className}>
            {!width || loading ? (
                <div className="skeleton-wrapper">
                    <Skeleton height={260} width="100%" className="skeleton-chart" />
                    <div className="skeleton-text-wrapper">
                        <Skeleton height={15} width={90} className="skeleton-text" />
                    </div>
                </div>
            ) : (
                <ResponsiveContainer width={width} height={300}>
                    <AreaChart {...props}>
                        <Line type="monotone" dataKey="value" stroke="#8884d8" />
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#003735" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#f4f4f4" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Tooltip />
                        <CartesianGrid stroke="#dfefee" vertical={false} height={1} />
                        <XAxis
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tick={{ fill: '#000', fontSize: '12px' }}
                            height={50}
                        />
                        <Area
                            type="monotone"
                            dataKey="value"
                            name="Transactions"
                            stroke="#003735"
                            fillOpacity={0.5}
                            fill="url(#colorUv)"
                        />
                        <Brush dataKey="value" height={30} stroke="#003735" />
                        <Legend
                            verticalAlign="bottom"
                            iconType="circle"
                            height={36}
                            wrapperStyle={{
                                paddingTop: '20px',
                            }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default styled(Chart)`
    margin: 20px 0;
    .skeleton-text-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .skeleton-wrapper {
        text-align: center;
    }
    .skeleton-chart {
        border-radius: 0px;
    }
    .skeleton-text {
        margin-top: 10px;
    }
`;
