import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns-tz';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { InfoPanel, TransactionCard, Loading } from '../layout';
import { Button, Container, NavButton, Typography } from '../components';
import { useAPI, useUtils } from '../hooks';

interface TransactionDetailsProps {
    className?: string;
}

type Input = {
    address: string;
    amount: number;
    category: string;
};

type Output = {
    address: string;
    amount: number;
    category: string;
    currency: string;
    timelock: number;
};

type Transaction = {
    amount: number;
    coinbase: boolean;
    coinstake: boolean;
    fee: number;
    inputs: Input[];
    outputs: Output[];
    size: number;
    timestamp: number;
    txid: string;
    confirmations: number;
    height: number;
};

const TransactionDetails = ({ className }: TransactionDetailsProps) => {
    const history = useHistory();
    const { hash } = useParams();
    const { isLoading, makeRequest } = useAPI();
    const { getStatus, getStatusTitle } = useUtils();
    const [transaction, setTransaction] = useState<Transaction>();

    useEffect(() => {
        setTransaction(undefined);

        makeRequest({
            method: 'v2/transaction',
            value: hash,
        }).then((res) => {
            setTransaction(res);
        });
    }, [hash]);

    return (
        <>
            <Helmet>
                <title>Transaction {hash}</title>
                <meta property="title" content={`AOK Transaction ${hash}`} />
                <meta name="description" content={`Details of transaction ${hash}`} />
                <meta property="image" content="https://aok.network/resource/images/dev_center_img_01_02.jpg" />
                <meta property="og:title" content={`AOK Transaction ${hash}`} />
                <meta property="og:description" content={`Details of transaction ${hash}`} />
                <meta property="og:image" content="https://aok.network/resource/images/dev_center_img_01_02.jpg" />
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography component="h1" color="primary">
                        Transaction
                    </Typography>
                </div>
                <InfoPanel
                    loading={!transaction}
                    title="Transaction hash"
                    description={transaction?.txid}
                    data={[
                        { title: 'Height', description: String(transaction?.height) },
                        { title: 'Size', description: String(transaction?.size) },
                        {
                            title: 'Amount Transfered',
                            description: String(transaction?.amount.toFixed(2)),
                            isCoin: true,
                        },
                        { title: 'Confirmations', description: String(transaction?.confirmations) },
                        {
                            title: 'Type',
                            description: transaction?.coinstake
                                ? 'Coinstake'
                                : transaction?.coinbase
                                ? 'Coinbase'
                                : undefined,
                        },
                    ].filter((data) => data.description !== undefined)}
                    qrData={transaction?.txid}
                />
                {history.length > 2 && (
                    <NavButton
                        leftIcon={<AiOutlineLeft />}
                        onClick={() => history.goBack()}
                        className="nav-button"
                        title="Back"
                    />
                )}
                {transaction ? (
                    <TransactionCard
                        background
                        type="transaction"
                        date={format(new Date(transaction.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss')}
                        hash={transaction.txid}
                        from={transaction.inputs}
                        to={transaction.outputs}
                        coinbase={transaction.coinbase}
                        coinstake={transaction.coinstake}
                        key={transaction.txid}
                        fee={transaction.fee}
                    />
                ) : (
                    <Loading />
                )}
            </Container>
        </>
    );
};

export default styled(TransactionDetails)`
    flex-direction: column;
    display: flex;

    .nav-button {
        margin: 22px 0;
    }
    .subtitle {
        display: block;
        margin-bottom: 20px;
    }
    .btn {
        margin: 20px 0;
        align-self: center;
    }
    .cca-tag {
        display: inline-block;
        margin-left: 9px;
    }
`;
