import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { AiFillSketchCircle, AiOutlineRight } from 'react-icons/ai';
import { format } from 'date-fns-tz';
import { useAPI } from '../hooks';
import { Container, Typography, InfoBlock, Button, NavButton } from '../components';
import { Table, Loading } from '../layout';
import { ReactComponent as CoinLogo } from '../img/icon-coin.svg';

interface TokenTransactionsProps {
    className?: string;
}

type Transaction = {
    amount: number;
    blockhash: string;
    txhash: string;
    timestamp: number;
    height: number;
};

const TokenTransactions = ({ className }: TokenTransactionsProps) => {
    const history = useHistory();
    const { token } = useParams();
    const { isLoading, makeRequest } = useAPI();
    const [page, setPage] = useState<number>(1);
    const [transactions, setTransactions] = useState<Transaction[]>([]);

    const getNextPage = () => {
        makeRequest({
            method: 'v2/transactions',
            value: String(token).toUpperCase(),
            params: {
                page,
            },
        }).then((res) => {
            setPage((prev) => prev + 1);
            setTransactions((prev) =>
                [
                    ...res.map((tx) => ({
                        ...tx,
                        timestamp: format(new Date(tx.timestamp * 1000), 'yyyy-MM-dd HH:mm:ss'),
                    })),
                    ...prev,
                ].filter((value, index, self) => {
                    return self.findIndex((v) => v.txhash === value.txhash) === index;
                }),
            );
        });
    };

    useEffect(() => {
        if (token) {
            getNextPage();
        }
    }, [token]);

    return (
        <>
            <Helmet>
                <title>Token Transactions</title>
            </Helmet>
            <Container className={className}>
                <div className="header">
                    <Typography variant="h1" component="div">
                        {token.toUpperCase()} Transactions
                    </Typography>
                </div>
                <NavButton
                    rightIcon={<AiOutlineRight />}
                    onClick={() => history.push(`/holders/${token}`)}
                    className="nav-button"
                    title={`${token.toUpperCase()} Holders`}
                />
                {transactions.length > 0 && (
                    <div className="table-wrapper">
                        <Table
                            className="table"
                            data={transactions}
                            headers={[
                                {
                                    key: 'height',
                                    title: 'Height',
                                    sortable: false,
                                    isMobile: true,
                                },
                                {
                                    key: 'txhash',
                                    title: 'Transaction Hash',
                                    className: 'mono-font',
                                    sortable: false,
                                    isMobile: true,
                                    href: '/transaction/$text',
                                },
                                {
                                    key: 'amount',
                                    title: 'Amount',
                                    sortable: false,
                                },
                                {
                                    key: 'timestamp',
                                    title: 'Date & time',
                                    sortable: false,
                                    isMobile: true,
                                },
                            ]}
                        />
                    </div>
                )}

                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="button-load">
                        <Button backgroundColor="secondary" size="lg" title="Load more" onClick={getNextPage} />
                    </div>
                )}
            </Container>
        </>
    );
};

export default styled(TokenTransactions)<TokenTransactionsProps>`
    flex-direction: column;
    display: flex;
    .nav-button {
        margin: 22px 0;
    }
    .icon {
        font-size: 16px;
        margin-left: 4px;
        line-heigth: 20px;
    }
    .description {
        display: flex;
        align-items: center;
    }
    #info-blocks {
        display: flex;
        flex-wrap: wrap;
        .active-block {
            flex: 2;
        }

        .common-block {
            flex: 1;
        }
    }
    .table-wrapper {
        margin: 0 0 20px 0;
    }
    .button-load {
        display: flex;
        justify-content: center;
    }
`;
