import React from 'react';
import { SnackbarContent } from 'notistack';
import styled from 'styled-components';
import { Typography } from '.';

interface SnackbarProps {
    className?: string;
    message: string;
    id: number;
}

const Snackbar = React.forwardRef(({ message, id, className }: SnackbarProps, ref) => {
    return (
        <SnackbarContent ref={ref} className={className}>
            <Typography variant="body1" component="p" color="initial">
                {message}
            </Typography>
        </SnackbarContent>
    );
});

export default styled(Snackbar)<SnackbarProps>`
    display: flex:
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: ${({ theme }) => theme.palette.secondary};
    border-radius: 4px;
    min-height: 55px;
    width: 320px;
`;
